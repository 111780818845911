import banner1 from "../Asset/Banner/banner1.jpg";
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import comann from "../Asset/Banner/bannerdemo1.jpeg"
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import peramount from "../Asset/Banner/peramount.jpg";
import banner2 from "../Asset/Banner/banner2.jpg";
import banner3 from "../Asset/Banner/banner3.jpg";
import banner4 from "../Asset/Banner/banner4.jpg";
import banner5 from "../Asset/Banner/banner5.jpg";
import ban1 from "../Asset/Banner/ban1.jpeg";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import Footer from "../Components/Footer";
import Enquiryform from "../Components/Enquiryform";
import { MetaTags } from 'react-meta-tags';
const Decorebydestion = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
      <MetaTags>
        <title> Destination Wedding Company in Malad | Celebrity Decorator</title>
        <meta title="  Destination Wedding Company in Malad | Celebrity Decorator" />
        <meta name="description" content=" Elevate your celebration with the top Destination Wedding Company in Malad. From scenic venues to meticulous planning, we craft moments that last a lifetime." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/destination-wedding-company-malad" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Destination Wedding Company in Malad | Celebrity Decorator" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content=" Elevate your celebration with the top Destination Wedding Company in Malad. From scenic venues to meticulous planning, we craft moments that last a lifetime." />
        <meta property="og:image" content="https://celebritydecorator.com/static/media/Logo.32e388932c0fee99c1be.png" />
      </MetaTags>
      <section
        id="breadcrumb-section"
        className="breadcrumb-section clearfix margintop"
      >
        <div
          className="jarallax"
          style={{
            backgroundImage: "none",
            zIndex: 0,
            backgroundSize:"cover",
            backgroundImage: `url(${comann})`,
          }}
        >
          <div className="overlay-black">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* breadcrumb-title - start */}
                  <div className="breadcrumb-title text-center mb-50">
                    <span className="sub-title">Celebrity Decorator</span>
                    <h2 className="big-title">
                      <strong>DECORE BY DESTINATION</strong>
                    </h2>
                  </div>
                  {/* breadcrumb-title - end */}
                  {/* breadcrumb-list - start */}
                  <div className="breadcrumb-list">
                    <ul>
                      <li className="breadcrumb-item">
                        <a href="index-1.html" className="breadcrumb-link">
                          Home
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        about us
                      </li>
                    </ul>
                  </div>
                  {/* breadcrumb-list - end */}
                </div>
              </div>
            </div>
          </div>
          <div
            id="jarallax-container-0"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
              pointerEvents: "none",
              zIndex: -100,
            }}
          >
            <div
              style={{
                backgroundPosition: "50% 50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${ban1})`,
                position: "fixed",
                backgroundSize: "100% 100%",
                top: 0,
                left: 0,

                height: "553.66px",
                overflow: "hidden",
                pointerEvents: "none",
                marginTop: "49.17px",
              }}
            />
          </div>
        </div>
      </section>
      <section
        id="service-section"
        className="service-section sec-ptb-100 clearfix"
      >
        <div className="container">
          <div className="service-wrapper">
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-7">
                  {" "}
                  <div className="  float-left">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={peramount} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className="col-md-5">
                  {" "}
                  <div className="demostyle">
                    <div className="service-content float-right">
                      <div className="service-title mb-30">
                        <h2 className="title-text">
                          <strong className="colorwhite fontstyle">
                            karjat permount
                          </strong>
                        </h2>
                      </div>
                      <p
                        className="service-description black-color mb-30 colorwhite"
                        style={{ textAlign: "justify" }}
                      >
                       
                       Discover tranquility at Karjat Permount, a luxury resort nestled in the Western Ghats. Surrounded by lush greenery, enjoy breathtaking views from well-appointed accommodations. Indulge in spa treatments, adventure sports, and relax by the pool. With gourmet dining and top-notch facilities, this resort is perfect for romantic escapes, family vacations, or corporate retreats. Experience the essence of nature's grandeur at Karjat Permount—a celebration of life in every moment.
                      </p>

                      <div className="text-left">
                        <button
                          role="button"
                          tabIndex={0}
                          href="#!"
                          className="custom-btn "
                          onClick={() => setModalShow(true)}
                        >
                          enquiry now
                        </button>

                        <div className="modelpop" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-5 order-md-1 order-2">
                  <div className="service-content float-left">
                    <div className="service-title mb-30">
                      <h2 className="title-text">
                        <strong className="colorwhite fontstyle">
                        BAPS Shri Swaminarayan Mandir
                        </strong>
                      </h2>
                    </div>
                    <p
                      className="service-description black-color mb-30 colorwhite"
                      style={{ textAlign: "justify" }}
                    >
                   BAPS Shri Swaminarayan Mandir is not just a religious sanctuary; it is a serene resort for the soul. Nestled in its architectural splendor, this Hindu temple in [insert location] offers a tranquil escape from the hustle of daily life. The intricately carved marble and sandstone structure stands as a testament to timeless artistry and devotion. Visitors find solace in the peaceful atmosphere, surrounded by lush gardens and reflective pools. The Mandir serves as a cultural oasis, hosting events that celebrate spirituality, art, and community. Whether seeking spiritual enlightenment or a quiet retreat, BAPS Shri Swaminarayan Mandir provides a rejuvenating experience for all who step through its ornate gates.
                    </p>

                    <div className="text-left">
                      <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                        enquiry now
                      </button>
                      <div className="modelpop" />
                    </div>
                  </div>
                </div>
                <div className="col-md-7 order-md-2 order-1">
                  <div className=" float-right">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner1} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-7">
                  {" "}
                  <div className="  float-left">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={peramount} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className="col-md-5">
                  {" "}
                  <div className="demostyle">
                    <div className="service-content float-right">
                      <div className="service-title mb-30">
                        <h2 className="title-text">
                          <strong className="colorwhite fontstyle">
                          Sarita Lawns & Banquets
                          </strong>
                        </h2>
                      </div>
                      <p
                        className="service-description black-color mb-30 colorwhite"
                        style={{ textAlign: "justify" }}
                      >
                        
Sarita Lawns & Banquets offers an exquisite venue for your special occasions. Nestled in a picturesque setting, our sprawling lawns provide a charming backdrop for weddings, receptions, and events. Our banquets are elegantly designed, equipped with modern amenities, and can accommodate gatherings of various sizes. With meticulous attention to detail, we ensure seamless event planning and execution. From lush greenery to sophisticated interiors, Sarita Lawns & Banquets creates a captivating atmosphere. Our dedicated team strives to make your celebrations truly memorable. Discover the perfect blend of nature and luxury at Sarita Lawns & Banquets – where every event is an extraordinary experience.
                      </p>

                      <div className="text-left">
                        <button
                          role="button"
                          tabIndex={0}
                          href="#!"
                          className="custom-btn "
                          onClick={() => setModalShow(true)}
                        >
                          enquiry now
                        </button>

                        <div className="modelpop" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Enquiryform show={modalShow} onHide={() => setModalShow(false)} />
      <p style={{display:"none"}}>
Are you dreaming of a magical destination wedding in Malad? Look no further! Our destination wedding company in Malad is here to turn your dreams into reality. Malad, with its stunning landscapes and picturesque venues, provides the perfect backdrop for your special day. Our dedicated team of experts is committed to making your destination wedding in Malad an unforgettable experience.

As a leading destination wedding company in Malad, we understand the importance of every detail in creating the perfect celebration. From breathtaking beachfront ceremonies to luxurious banquet halls, we offer a variety of options to suit your preferences. Our experienced planners will work closely with you to tailor every aspect of your wedding, ensuring that it reflects your unique style and vision.

Choosing our destination wedding services in Malad means entrusting your special day to a team that is passionate about creating moments that last a lifetime. Our commitment to excellence and attention to detail sets us apart as the premier choice for couples seeking a seamless and memorable wedding experience in Malad.

Imagine exchanging vows surrounded by the beauty of Malad's natural wonders, with the sound of the waves or the rustle of palm trees as your backdrop. Our destination wedding company in Malad is dedicated to curating these magical moments for you and your loved ones.

Whether you envision an intimate celebration or a grand affair, our Malad destination wedding services cater to all preferences and sizes. Malad, with its charm and allure, provides the ideal canvas for crafting the wedding of your dreams.

Contact our destination wedding company in Malad today, and let us weave the magic of your love story into the fabric of this enchanting destination. Malad awaits you, and so do we, to create the perfect beginning to your happily ever after. Malad, the destination of dreams for your wedding – choose us for a celebration that echoes the beauty of this incredible locale.</p>
      <Footer />
    </div>
  );
};

export default Decorebydestion;
