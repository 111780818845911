import React from 'react'

import banner1 from "../Asset/Banner/banner1.jpg"
import Footer from '../Components/Footer'
import { FaLocationDot } from 'react-icons/fa6';
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import Enquiryform from '../Components/Enquiryform';
import { MetaTags } from 'react-meta-tags';
const MahendiArtist = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
      <MetaTags>
        <title> Mehendi Artists for Wedding In Mumbai | Celebrity Decorator </title>
        <meta title="  Mehendi Artists for Wedding In Mumbai | Celebrity Decorator " />
        <meta name="description" content="Explore the finest Mehendi Artists for Weddings in Mumbai. Elevate your wedding celebrations with intricate and captivating designs that add a touch ." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/mehendi-artists-wedding-mumbai" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Mehendi Artists for Wedding In Mumbai | Celebrity Decorator " />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content="Explore the finest Mehendi Artists for Weddings in Mumbai. Elevate your wedding celebrations with intricate and captivating designs that add a touch ." />
        <meta property="og:image" content="https://celebritydecorator.com/static/media/Logo.32e388932c0fee99c1be.png" />
      </MetaTags>
       <div>
           <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
  <div
    className="jarallax"
    style={{ backgroundImage: "none", backgroundSize:"cover", zIndex: 0 , backgroundImage: `url(${comann})` , }}
    
  >
    <div className="overlay-black">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {/* breadcrumb-title - start */}
            <div className="breadcrumb-title text-center mb-50">
              <span className="sub-title">Celebrity Decorator</span>
              <h2 className="big-title">
                <strong>Mahendi Artist</strong> 
              </h2>
            </div>
            {/* breadcrumb-title - end */}
            {/* breadcrumb-list - start */}
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-item">
                  <a href="index-1.html" className="breadcrumb-link">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  about us
                </li>
              </ul>
            </div>
            {/* breadcrumb-list - end */}
          </div>
        </div>
      </div>
    </div>
    <div
      id="jarallax-container-0"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        pointerEvents: "none",
        zIndex: -100
      }}
    >
      <div
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${banner1})` ,
          position: "fixed",
          top: 0,
          left: 0,
  
          height: "553.66px",
          overflow: "hidden",
          pointerEvents: "none",
          marginTop: "49.17px",
         
        }}
      />
    </div>
  </div>
</section>
<div >
   
    <Enquiryform
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
</div>
<div className="grid container">
  <div className="row">
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/vendorThumbnailImage/5bbc57d1c1707__Arshaan-Gandhi-Wedding-Photographer.jpg" alt="Snowy Mountains" />
            <div className="card__content">

              <h3 className="card__header" style={{margin:"0px"}}>Ajay Sharma Art</h3>
              <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/vendorThumbnailImage/5bbde95d0d935__Alankritaa-Mehendi-Artists-min.jpg" alt="Snowy Mountains" />
            <div className="card__content">
              <h3 className="card__header" style={{margin:"0px"}}>Alankritaa</h3>
              <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    
  
  </div>
  
        
      </div>

<p style={{display:"none"}}>
  
Choosing the ideal Mehendi artist for your Mumbai wedding is a pivotal decision in ensuring that your bridal henna embodies the cultural richness and intricate beauty associated with this traditional art form. Mumbai, a city brimming with vibrant festivities, offers an array of skilled Mehendi artists capable of transforming your hands and feet into exquisite canvases on your special day.

To initiate your search, explore local directories and online platforms featuring portfolios of experienced Mehendi artists. Delve into key considerations such as expertise, style, and client reviews to narrow down your choices. Selecting an artist whose style aligns seamlessly with your vision for the perfect bridal henna is paramount.

Mumbai's Mehendi artists showcase expertise across a spectrum, from traditional Indian designs to contemporary and fusion styles. Communicate your preferences during discussions with potential artists, emphasizing intricate patterns, cultural significance, and the overall aesthetic you envision for your Mehendi ceremony.

Word-of-mouth recommendations prove invaluable in identifying skilled Mehendi artists. Consult recently married friends, family, and colleagues in Mumbai for insights into reputable artists who have showcased their talents at various weddings. This ensures a tried-and-tested quality for your special day.

Schedule consultations with shortlisted artists to discuss design details, pricing, and availability. Personal interactions provide insights into their professionalism and creativity, fostering a seamless collaboration leading up to your wedding day.

In a city celebrated for its cultural diversity and lively weddings, Mumbai offers abundant choices for Mehendi artists. By focusing on key factors such as expertise, style, and personal recommendations, you can discover the perfect artist who will elevate your Mehendi ceremony into a visually stunning and culturally rich experience.
</p>
<Footer/>

    </div>
    </div>
  )
}

export default MahendiArtist
