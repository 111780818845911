import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Navigation } from "swiper/modules";
import "../css/Aboutus.css";
const Testimonial2 = () => {
  return (
    <div>
      <Swiper
        style={{
          height: "200px",
        }}

        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1000: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode]}
        className="mySwiper1"
      >
        <SwiperSlide>
          <div className="sliderimag">
          <figure className="snip1192">
            <blockquote>
            "Thanks to Celebrity Decorator, our event radiated style and sophistication – an absolute transformation!"{" "}
            </blockquote>
            <div className="author">
            
              <h5>
                reena sani
              </h5>
            </div>
          </figure>
          </div>
          
        </SwiperSlide>
        <SwiperSlide>
          <div className="sliderimag">
          <figure className="snip1192">
            <blockquote>
            "Celebrity Decorator has an extraordinary gift for turning spaces into works of art – our event was a testament to their unmatched talent."
            </blockquote>
            <div className="author">
             
              <h5>
                Rahul Mahto
              </h5>
            </div>
          </figure>
          </div>
        
        </SwiperSlide>
        <SwiperSlide>
          <div className="sliderimag">
          <figure className="snip1192">
            <blockquote>
            "Incredible design sensibility –  Celebrity Decorator effortlessly brought elegance and charm to our event."
            </blockquote>
            <div className="author">
              
              <h5>
                Mina Soti
              </h5>
            </div>
          </figure>
          </div>
          
        </SwiperSlide>
        <SwiperSlide>
          <div className="sliderimag">
          <figure className="snip1192">
            <blockquote>
            "A big 'wow' thanks to Celebrity Decorator for creating an enchanting ambiance at our event."
            </blockquote>
            <div className="author">
             
              <h5>
                puran patel
              </h5>
            </div>
          </figure>
          </div>
         
        </SwiperSlide>
        <SwiperSlide>
          <div className="sliderimag">
          <figure className="snip1192">
            <blockquote>
            "Flawless execution and impeccable taste – Celebrity Decorator elevated our event to new heights of aesthetic brilliance."
            </blockquote>
            <div className="author">
             
              <h5>
                Roma singh
              </h5>
            </div>
          </figure>
          </div>
         
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Testimonial2;
