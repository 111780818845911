import React from 'react'
import { MetaTags } from 'react-meta-tags';
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import banner1 from "../Asset/Banner/banner1.jpg"
import Footer from '../Components/Footer'
import { FaLocationDot } from 'react-icons/fa6';
import Enquiryform from '../Components/Enquiryform';
const MakeupArtists = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
      <MetaTags>
        <title> Makeup Artists for Wedding in Mumbai | Celebrity Decorator</title>
        <meta title="  Makeup Artists for Wedding in Mumbai | Celebrity Decorator" />
        <meta name="description" content="Discover skilled Makeup Artists for Weddings in Mumbai. Our experts specialize in crafting stunning bridal looks, ensuring your special day is adorned." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/makeup-artists-wedding-mumbai" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Makeup Artists for Wedding in Mumbai | Celebrity Decorator" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content="Discover skilled Makeup Artists for Weddings in Mumbai. Our experts specialize in crafting stunning bridal looks, ensuring your special day is adorned." />
        <meta property="og:image" content="https://celebritydecorator.com/static/media/Logo.32e388932c0fee99c1be.png" />
      </MetaTags>
    <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
<div
className="jarallax"
style={{ backgroundImage: "none", backgroundSize:"cover", zIndex: 0 , backgroundImage: `url(${comann})` , }}

>
<div className="overlay-black">
<div className="container">
 <div className="row justify-content-center">
   <div className="col-lg-6 col-md-12 col-sm-12">
     {/* breadcrumb-title - start */}
     <div className="breadcrumb-title text-center mb-50">
       <span className="sub-title">Celebrity Decorator</span>
       <h2 className="big-title">
         <strong>MakeupArtists</strong> 
       </h2>
     </div>
     {/* breadcrumb-title - end */}
     {/* breadcrumb-list - start */}
     <div className="breadcrumb-list">
       <ul>
         <li className="breadcrumb-item">
           <a href="index-1.html" className="breadcrumb-link">
             Home
           </a>
         </li>
         <li className="breadcrumb-item active" aria-current="page">
           about us
         </li>
       </ul>
     </div>
     {/* breadcrumb-list - end */}
   </div>
 </div>
</div>
</div>
<div
id="jarallax-container-0"
style={{
 position: "absolute",
 top: 0,
 left: 0,
 width: "100%",
 height: "100%",
 overflow: "hidden",
 pointerEvents: "none",
 zIndex: -100
}}
>
<div
 style={{
   backgroundPosition: "50% 50%",
   backgroundSize: "cover",
   backgroundRepeat: "no-repeat",
   backgroundImage: `url(${banner1})` ,
   position: "fixed",
   top: 0,
   left: 0,

   height: "553.66px",
   overflow: "hidden",
   pointerEvents: "none",
   marginTop: "49.17px",
  
 }}
/>
</div>
</div>
</section>
<div  >

<div className="grid container">
  <div className="row">
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/vendorThumbnailImage/5bdafae7768c9__Areej-S-Makeup-Studio.jpg" alt="Snowy Mountains" />
            <div className="card__content">

              <h3 className="card__header" style={{margin:"0px"}}>Areej S Makeup Studio</h3>
             <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/vendorThumbnailImage/5bc06e04c3bfa__Anuj%20Dogra-Make-up%20Artist.jpg" alt="Snowy Mountains" />
            <div className="card__content">
              <h3 className="card__header" style={{margin:"0px"}}>Anuj Dogra Make-Up</h3>
             <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    
  
  </div>
  
        
      </div>
<Enquiryform
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
</div>


<p style={{display:"none"}}>
  
When it comes to planning a wedding in Mumbai, one of the most crucial decisions is choosing the right Makeup Artists for Wedding in Mumbai. The vibrant city offers a myriad of talented professionals specializing in bridal makeup, ensuring that every bride achieves her dream look on the big day.

Finding the ideal Makeup Artists for Wedding in Mumbai involves careful research and consideration. Brides-to-be should explore online portfolios and reviews, focusing on keywords such as "Makeup Artists for Wedding" and "Mumbai," to narrow down their options. This ensures a tailored search that aligns with the local wedding scene.

Communication is paramount in the selection process of Makeup Artists for Wedding in Mumbai. It's essential to choose an artist who not only understands the bride's preferences but is also open to collaboration. Keywords like "consultation" and "trial makeup" play a pivotal role in establishing clear communication and achieving the desired bridal look.

On the wedding day, the chosen Makeup Artists for Wedding in Mumbai should exhibit professionalism and efficiency. Being punctual and adhering to the agreed-upon schedule are key factors in ensuring that the bride looks flawless and radiant as she walks down the aisle. Trusting in the expertise of these professionals guarantees a stress-free and beautiful bridal makeup experience.

In conclusion, selecting the perfect Makeup Artists for Wedding in Mumbai is a critical step in realizing the dream wedding look. By incorporating keywords like "Makeup Artists for Wedding" strategically throughout the planning process, brides can navigate the bustling city's offerings and secure the services of a talented professional who will make their special day truly memorable.
</p>
<Footer/>

</div>
  )
}

export default MakeupArtists
