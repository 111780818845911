import React from "react";
import banner1 from "../Asset/Banner/banner1.jpg";
import Footer from "../Components/Footer";
import p1 from "../Asset/img1/p1.jpg"
import p2 from "../Asset/img1/p2.jpg"
import p3 from "../Asset/img1/p3.jpg"
import p4 from "../Asset/img1/p4.jpg"
import p5 from "../Asset/img1/p5.jpg"
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import Enquiryform from "../Components/Enquiryform";
import { MetaTags } from 'react-meta-tags';  
const Weddingservice = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
      <MetaTags>
        <title> Wedding Services Company In Malad | Celebrity Decorator</title>
        <meta title="  Wedding Services Company In Malad | Celebrity Decorator" />
        <meta name="description" content=" Discover an unparalleled Wedding Services Company in Malad with our dedicated team. Choose us for an elegant and memorable celebration." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/wedding-services-company-malad" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Wedding Services Company In Malad | Celebrity Decorator" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content=" Discover an unparalleled Wedding Services Company in Malad with our dedicated team. Choose us for an elegant and memorable celebration." />
        <meta property="og:image" content="https://celebritydecorator.com/static/media/Logo.32e388932c0fee99c1be.png" />
      </MetaTags>
      <section
        id="breadcrumb-section"
        className="breadcrumb-section clearfix margintop"
      >
        <div
          className="jarallax"
          style={{
            backgroundImage: "none",
            zIndex: 0,
            backgroundSize:"cover",
            backgroundImage: `url(${comann})`,
          }}
        >
          <div className="overlay-black">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* breadcrumb-title - start */}
                  <div className="breadcrumb-title text-center mb-50">
                    <span className="sub-title">DECORE BY DESTINATION</span>
                    <h2 className="big-title">
                      <strong>Wedding Service</strong>
                    </h2>
                  </div>
                  {/* breadcrumb-title - end */}
                  {/* breadcrumb-list - start */}
                  <div className="breadcrumb-list">
                    <ul>
                      <li className="breadcrumb-item">
                        <a href="index-1.html" className="breadcrumb-link">
                          Home
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        about us
                      </li>
                    </ul>
                  </div>
                  {/* breadcrumb-list - end */}
                </div>
              </div>
            </div>
          </div>
          <div
            id="jarallax-container-0"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
              pointerEvents: "none",
              zIndex: -100,
            }}
          >
            <div
              style={{
                backgroundPosition: "50% 50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${banner1})`,
                position: "fixed",
                top: 0,
                left: 0,

                height: "553.66px",
                overflow: "hidden",
                pointerEvents: "none",
                marginTop: "49.17px",
              }}
            />
          </div>
        </div>
      </section>
      <section id="service-section" className="service-section sec-ptb-100 clearfix">
  <div className="container">
    <div className="service-wrapper">
      <div className="service-item clearfix">
        <div className="row">
          <div className="col-md-7">
            {" "}
            <div className="  float-left">
              <div className="big-image">
                <img
                  className="height440"
                  src={banner1}
                  alt="Image_not_found"
                />
              </div>
            </div>
          </div>
          <div className="col-md-5">
            {" "}
            <div className="service-content float-right">
              <div className="service-title mb-30">
                <h2 className="title-text">

                  <strong className="colorwhite fontstyle">Venue Bookings</strong>
                </h2>
              </div>
              <p
                className="service-description black-color mb-30 colorwhite"
                style={{ textAlign: "justify" }}
              >
               

               Elevate your wedding experience with Celebrity Decorator, your premier Wedding Services Company in Malad. Our expert team specializes in transforming your dream wedding into a reality, curating enchanting and personalized decor that reflects your unique style. From exquisite floral arrangements to elegant lighting, we bring a touch of celebrity glamour to every detail. Our Malad-based services ensure a seamless and memorable celebration, blending sophistication and creativity to exceed your expectations. Let Celebrity Decorator turn your special day into a masterpiece, leaving you and your guests enchanted. Choose the pinnacle of wedding services in Malad for a truly star-studded celebration.
              </p>
             
             
              <div className="text-left">
                <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                         Enquiry Now
                      </button>
                <div className="modelpop" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-item clearfix">
        <div className="row">
          <div className="col-md-5 order-md-1 order-2">
            <div className="service-content float-left">
              <div className="service-title mb-30">
                <h2 className="title-text colorwhite fontstyle">
                 
                  <strong className="colorwhite fontstyle">Design By Concept </strong>
                </h2>
              </div>
              <p
                className="service-description black-color mb-30 colorwhite"
                style={{ textAlign: "justify" }}
              >
              Design By Concept: Your Premier Event Management Company in Malad. Elevate your celebrations with our expert planning and seamless coordination. From weddings to corporate events, we specialize in creating memorable experiences tailored to your vision. Our seasoned team manages every detail, ensuring a stress-free and flawless execution. Choose from our curated venues, and let us handle logistics, decor, and entertainment. Whether it's a grand wedding or a corporate gala, we bring creativity and precision to every event. Trust Design By Concept to turn your ideas into unforgettable moments. Contact us today for exceptional event management services in Malad.
              </p>
            
              <div className="text-left">
                <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                         Enquiry Now
                      </button>
                <div className="modelpop" />
              </div>
            </div>
          </div>
          <div className="col-md-7 order-md-2 order-1">
            <div className=" float-right">
              <div className="big-image">
                <img
                  className="height440"
                  src={p1}
                  alt="Image_not_found"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-item clearfix">
        <div className="row">
          <div className="col-md-7">
            <div className=" float-left">
              <div className="big-image">
                <img
                  className="height440"
                  src={p3}
                  alt="Image_not_found"
                />
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="service-content float-right">
              <div className="service-title mb-30">
                <h2 className="title-text colorwhite fontstyle">
                 
                 <strong className="colorwhite fontstyle">Planning  And Coordination </strong>
                </h2>
              </div>
              <p
                className="service-description black-color mb-30 colorwhite"
                style={{ textAlign: "justify" }}
              >
            
Planning and Coordination are the pillars of a successful event, especially when it comes to weddings. In Malad, a bustling suburb, finding a reliable Wedding Services Company is crucial. These experts specialize in meticulous planning and seamless coordination, ensuring your special day unfolds effortlessly. From venue selection to decor, catering, and entertainment, the Wedding Services Company in Malad takes care of every detail. Their seasoned professionals work closely with clients, turning dreams into reality. Entrusting your celebration to a skilled team ensures a stress-free experience, allowing you to savor every moment. Choose the best Wedding Services Company in Malad for a magical, well-coordinated celebration.
              </p>
    
              <div className="text-left">
                <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                         Enquiry Now
                      </button>
                <div className="modelpop" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-item clearfix">
        <div className="row">
          <div className="col-md-5 order-md-1 order-2">
            <div className="service-content float-left">
              <div className="service-title mb-30">
                <h2 className="title-text colorwhite fontstyle">

                  <strong className="colorwhite fontstyle">Entertainment</strong>
                </h2>
              </div>
              <p
                className="service-description black-color mb-30 colorwhite"
                style={{ textAlign: "justify" }}
              >
                Discover the epitome of elegance and excellence with our Wedding Services Company in Malad. Immerse yourself in a world of seamless celebrations where every detail is meticulously crafted to perfection. From exquisite venue selection to personalized décor, our team ensures your special day is a reflection of your dreams. Our Malad-based services extend beyond traditional expectations, offering bespoke solutions tailored to your unique preferences. Embrace a stress-free journey as we handle the intricacies of planning, leaving you to savor every moment. Trust our expertise to transform your wedding into a cherished memory. Elevate your celebration with the finest Wedding Services Company in Malad.
              </p>
           
              <div className="text-left">
                <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                         Enquiry Now
                      </button>
                <div className="modelpop" />
              </div>
            </div>
          </div>
          <div className="col-md-7 order-md-2 order-1">
            <div className=" float-right">
              <div className="big-image">
                <img
                  className="height440"
                  src={p2}
                  alt="Image_not_found"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-item clearfix">
        <div className="row">
          <div className="col-md-7">
            <div className=" float-left">
              <div className="big-image">
                <img
                  className="height440"
                  src={p4}
                  alt="Image_not_found"
                />
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="service-content float-right">
              <div className="service-title mb-30">
                <h2 className="title-text colorwhite fontstyle">

                  <strong className="colorwhite fontstyle">Production And Execution </strong>
                </h2>
              </div>
              <p
                className="service-description black-color mb-30 colorwhite"
                style={{ textAlign: "justify" }}
              >
               Planning a dream wedding? Look no further! Our Wedding Services Company in Malad is here to turn your special day into a magical experience. From exquisite decor to flawless execution, we handle it all. Our dedicated team ensures every detail is perfect, creating memories that last a lifetime. Choose us for a stress-free journey from conception to celebration. We offer personalized packages tailored to your desires and budget. With our expertise in production and execution, your wedding will be a seamless, joyous affair. Trust our Malad-based Wedding Services Company to make your big day truly unforgettable. Book your date now!
              </p>
    
              <div className="text-left">
                <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                         Enquiry Now
                      </button>
                <div className="modelpop" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-item clearfix">
        <div className="row">
          <div className="col-md-5 order-md-1 order-2">
            <div className="service-content float-left">
              <div className="service-title mb-30">
                <h2 className="title-text colorwhite fontstyle">
                <strong className="colorwhite fontstyle">Branding</strong>
                 
                </h2>
              </div>
              <p
                className="service-description black-color mb-30 colorwhite"
                style={{ textAlign: "justify" }}
              >
              Discover the epitome of elegance with our Wedding Services Company in Malad. Transform your special day into a timeless celebration with our meticulous planning and attention to detail. At [Your Company Name], we specialize in curating unforgettable weddings that reflect your unique style. From exquisite venue selection to personalized decor, our team is dedicated to crafting moments that last a lifetime. Trust us to orchestrate a seamless wedding experience, ensuring every element aligns with your vision. Embrace luxury, sophistication, and unparalleled service with our Wedding Services in Malad. Let us turn your dreams into reality, creating memories that resonate for years to come.
              </p>
           
              <div className="text-left">
                <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                       Enquiry Now
                      </button>
                <div className="modelpop" />
              </div>
            </div>
          </div>
          <div className="col-md-7 order-md-2 order-1">
            <div className=" float-right">
              <div className="big-image">
                <img
                  className="height440"
                  src={p5}
                  alt="Image_not_found"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<Enquiryform
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    <p style={{display:"none"}}>
      
Welcome to our premier Wedding Services Company in Malad, where dreams come to life and love takes center stage. At our Wedding Services Company in Malad, we understand the importance of your special day and strive to make it truly unforgettable.

Our dedicated team at the Wedding Services Company in Malad is committed to turning your wedding visions into reality. From elegant décor to seamless coordination, we take care of every detail, ensuring a stress-free and magical experience for you and your loved ones.

Malad, with its picturesque surroundings, provides the perfect backdrop for your celebration of love. Our Wedding Services Company in Malad takes pride in curating bespoke weddings that reflect your unique style and personality. Whether you envision a grand affair or an intimate gathering, we have the expertise to make it happen.

As the leading Wedding Services Company in Malad, we offer a range of services to cater to your every need. From venue selection and catering to floral arrangements and entertainment, we've got it all covered. Our experienced team works tirelessly to bring your vision to life, ensuring that every moment is filled with joy and romance.

Choose our Wedding Services Company in Malad for a seamless and unforgettable wedding experience. Let us be a part of your journey as you embark on this beautiful chapter of your life. Contact us today to discuss your dreams, and let our Wedding Services Company in Malad transform them into a reality. Your perfect day awaits with us, your trusted Wedding Services Company in Malad.
    </p>
      <Footer/>
    </div>
  );
};

export default Weddingservice;
