import React, { useRef, useLayoutEffect, useState }  from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import $ from "jquery";
import Form from 'react-bootstrap/Form';
const Enquiryform = (props) => {
  const [show, setShow] = useState(false);
  const formRef = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalShow, setModalShow] = React.useState(false);

  // email
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
 
  const [custContact, setCustContact] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [custMessage, setCustMessage] = useState("");
 //////////
 

 const Appoinment = (e) => {
  var body =
      '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#eb6e14">celebrity decorator Enquiry</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#0a500a"><strong>Name:</strong></td><td style="text-align:left">' + custName + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Email Id:</strong></td><td style="text-align:left">' + custEmail + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Contact No:</strong></td><td style="text-align:left">' + custContact + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Subject:</strong></td><td style="text-align:left">' + custSubject + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Message:</strong></td><td style="text-align:left">' + custMessage + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at celebrity decorator</p></div></body></html>';
  // console.log(custServices)
  $.post(
    "https://skdm.in/server/v1/send_lead_mail.php",
    {
      toEmail: "info@celebritydecorator.com",
      fromEmail: "skdmlead@gmail.com",
      bccMail: "skdmlead@gmail.com",
      mailSubject: "New Customer Lead",
      mailBody: body,
      accountName: "celebrity decorator",
      accountLeadSource: "https://canvasvilla.co.in/",
      accountLeadName: custName,
      // accountLeadEmail: custEmail,
    },
    function (dataa, status) {
      console.log("data :" + dataa);
      // console.log("name:" + custName);
      // console.log("name:" + custEmail);
      // console.log("name:" + custCheckIn);
    }
  );

  alert("Your Form has Submitted Our team will contact with You  soon.");
  e.preventDefault();
  return false;
};
  return (
    <div>
       <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Enquiry Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="formpermount form-1"> 

            <Form  ref={formRef}  {...props} >
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label style={{color:"#ffbe30"}}>Name</Form.Label>
        <Form.Control type="text" placeholder="Full Name"   onChange={(e) => setCustName(e.target.value)} />
      </Form.Group>
      
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label style={{color:"#ffbe30"}}>Email address</Form.Label>
        <Form.Control type="email" placeholder="name@example.com"   onChange={(e) => setCustEmail(e.target.value)}   />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label style={{color:"#ffbe30"}}>Mobile No</Form.Label>
        <Form.Control type="number" placeholder="123456789" onChange={(e) => setCustContact(e.target.value)}  />
      </Form.Group>
      
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label style={{color:"#ffbe30"}}>Service</Form.Label>
        <Form.Control type="text" placeholder="service"   onChange={(e) => setCustSubject(e.target.value)}  />
      </Form.Group>
    
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{color:"#ffbe30"}}>Small Details</Form.Label>
        <Form.Control as="textarea"   rows={3} onChange={(e) => setCustMessage(e.target.value)} />
      </Form.Group>   
     
      <Button variant="primary"  onClick={Appoinment}   type="submit" style={{backgroundColor:"#ffbe30"}}>
        Submit
      </Button>
    </Form>
            </div>
      </Modal.Body>
     
    </Modal>
    </div>
  )
}

export default Enquiryform
