import React from 'react'
import banner1 from "../Asset/Banner/banner1.jpg"
import Footer from '../Components/Footer'
import { FaLocationDot } from 'react-icons/fa6';
import Enquiryform from '../Components/Enquiryform';
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import { MetaTags } from 'react-meta-tags';
const DJS = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
      <MetaTags>
        <title> Best DJs for weddings In Mumbai | Celebrity Decorator </title>
        <meta title="  Best DJs for weddings In Mumbai | Celebrity Decorator " />
        <meta name="description" content=" Set the perfect tone for your wedding celebration with the Best DJs in Mumbai. Transform your special day into a dance-filled spectacle" />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/best-djs-weddings-mumbai" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Best DJs for weddings In Mumbai | Celebrity Decorator " />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content=" Set the perfect tone for your wedding celebration with the Best DJs in Mumbai. Transform your special day into a dance-filled spectacle" />
        <meta property="og:image" content="https://celebritydecorator.com/static/media/Logo.32e388932c0fee99c1be.png" />
      </MetaTags>
    <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
<div
className="jarallax"
style={{ backgroundImage: "none", backgroundSize:"cover", zIndex: 0 , backgroundImage: `url(${comann})` , }}

>
<div className="overlay-black">
<div className="container">
  <div className="row justify-content-center">
    <div className="col-lg-6 col-md-12 col-sm-12">
      {/* breadcrumb-title - start */}
      <div className="breadcrumb-title text-center mb-50">
        <span className="sub-title">Celebrity Decorator</span>
        <h2 className="big-title">
          <strong>Dj's</strong> 
        </h2>
      </div>
      {/* breadcrumb-title - end */}
      {/* breadcrumb-list - start */}
      <div className="breadcrumb-list">
        <ul>
          <li className="breadcrumb-item">
            <a href="index-1.html" className="breadcrumb-link">
              Home
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            about us
          </li>
        </ul>
      </div>
      {/* breadcrumb-list - end */}
    </div>
  </div>
</div>
</div>
<div
id="jarallax-container-0"
style={{
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  overflow: "hidden",
  pointerEvents: "none",
  zIndex: -100
}}
>
<div
  style={{
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${banner1})` ,
    position: "fixed",
    top: 0,
    left: 0,

    height: "553.66px",
    overflow: "hidden",
    pointerEvents: "none",
    marginTop: "49.17px",
   
  }}
/>
</div>
</div>
</section>
<div style={{padding:"50px 0px 50px 0px"}}>

<div className="grid container">
  <div className="row">
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/artistThumbnailImage/62c2c120639b6__Optimized-GaneshxTaj_46-1.jpg" alt="Snowy Mountains" />
            <div className="card__content">

              <h3 className="card__header" style={{margin:"0px"}}>DJ GANESH</h3>
              <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/artistThumbnailImage/5bdaf13fd052e__DJ-Udyan-Sagar.jpg" alt="Snowy Mountains" />
            <div className="card__content">
              <h3 className="card__header" style={{margin:"0px"}}>Udyan Sagar</h3>
              <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    
  
  </div>
  
        
      </div>
<Enquiryform
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
</div>
<p style={{display:"none"}}>
  
When it comes to creating an unforgettable ambiance for weddings in Mumbai, choosing the right DJ is essential. A skilled DJ can transform a wedding celebration into a lively and energetic event, leaving lasting memories for the couple and their guests.

One of the key factors to consider when selecting a DJ for your Mumbai wedding is experience. Look for DJs who have a wealth of experience in handling weddings, as they understand the unique dynamics of such events. A seasoned DJ can effortlessly blend different musical genres to cater to diverse tastes, ensuring that everyone on the guest list has a fantastic time.

Versatility is another crucial aspect to keep in mind. The best DJs for weddings in Mumbai are those who can adapt to different moods and settings throughout the event. Whether it's setting the tone during the ceremony, creating a romantic atmosphere for the first dance, or getting the party started during the reception, a versatile DJ can seamlessly navigate through these transitions.

Communication skills are vital for a wedding DJ as well. A good DJ will work closely with the couple to understand their musical preferences, ensuring that the playlist reflects their unique style and vision for the celebration. Clear communication also helps in coordinating with other vendors and adapting to any last-minute changes that may arise.

In Mumbai, where cultural diversity is celebrated, a DJ who can incorporate a variety of musical styles and regional influences can truly elevate the wedding experience. This ability to cater to different tastes ensures that all guests, regardless of their musical preferences, can participate in the festivities.

Lastly, reliability is key. The best wedding DJs in Mumbai are known for their punctuality and professionalism, arriving well-prepared with high-quality equipment to ensure a seamless musical experience throughout the celebration. By considering these factors and prioritizing experience, versatility, communication skills, cultural adaptability, and reliability, you can find the perfect DJ to make your Mumbai wedding an unforgettable celebration.
</p>
<Footer></Footer>
</div>
  )
}

export default DJS
