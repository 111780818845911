import React, { useRef, useLayoutEffect, useState,useEffect }  from 'react'
import Slider from "./Slider";
import a1 from "../Asset/Banner/banner1.jpg";
import Testimnial from "../Components/testimnial";
import Footer from "../Components/Footer";
import "../css/venue.css";
import i1 from "../Asset/icon/icon2/i1.png";
import i2 from "../Asset/icon/icon2/i2.png";
import i3 from "../Asset/icon/icon2/i3.png";
import i4 from "../Asset/icon/icon2/i4.png";
import i5 from "../Asset/icon/icon2/i5.png";
import i7 from "../Asset/icon/i7.png";
import $ from "jquery";
import img1 from "../Asset/img1/n1.jpg";
import img2 from "../Asset/img1/n2.jpg";
import "../css/Home.css";
import Fade from "react-reveal/Fade";
import { CgNotes } from "react-icons/cg";
import { TbMoneybag } from "react-icons/tb";
import { GiNotebook } from "react-icons/gi";
import banner1 from "../Asset/Banner/banner2.jpg";
import banner2 from "../Asset/Banner/banner1.jpg";
import banner3 from "../Asset/Banner/banner3.jpg"
import Awords from "../Components/Awords";
import Service from "../Components/Service";
import Testimonial2 from "../Components/Testimonial2";
import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";
import Flip from "react-reveal/Flip";
import Enquery from "../Asset/Enquery.jpg"
import banner4 from "../Asset/BAN4.jpg"
import banner5 from "../Asset/BAN5.jpg"
import banner6 from  "../Asset/BAN6.jpg"
import banner7 from "../Asset/BAN7.jpg"

import lightGallery from "lightgallery";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-pager.css";
import "lightgallery/css/lg-autoplay.css";
import { MetaTags } from 'react-meta-tags';
const Home = (props) => {
  useEffect(() => {
    // Initialize lightGallery
    lightGallery(document.getElementById("image-gallery"), {
      mode: "lg-fade",
      thumbnail: true,
      zoom: false,
      autoplay: false,
    });
  }, []);
  const [show, setShow] = useState(false);
  const formRef = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalShow, setModalShow] = React.useState(false);

  // email
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
 
  const [custContact, setCustContact] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [custMessage, setCustMessage] = useState("");
 //////////
 

 const Appoinment = (e) => {
  var body =
     '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#eb6e14">celebrity decorator Enquiry</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#0a500a"><strong>Name:</strong></td><td style="text-align:left">' + custName + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Email Id:</strong></td><td style="text-align:left">' + custEmail + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Contact No:</strong></td><td style="text-align:left">' + custContact + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at celebrity decorator</p></div></body></html>';
  // console.log(custServices)
  $.post(
    "https://skdm.in/server/v1/send_lead_mail.php",
    {
      toEmail: "info@celebritydecorator.com",
      fromEmail: "skdmlead@gmail.com",
      bccMail: "skdmlead@gmail.com",
      mailSubject: "New Customer Lead",
      mailBody: body,
      accountName: "celebrity decorator",
      accountLeadSource: "https://canvasvilla.co.in/",
      accountLeadName: custName,
      // accountLeadEmail: custEmail,
    },
    function (dataa, status) {
      console.log("data :" + dataa);
      // console.log("name:" + custName);
      // console.log("name:" + custEmail);
      // console.log("name:" + custCheckIn);
    }
  );

  alert("Your Form has Submitted Our team will contact with You  soon.");
  e.preventDefault();
  return false;
};

  return (
    <div>
        <MetaTags>
        <title>Top Wedding Planner & Decorator In Malad | Celebrity Decorator</title>
        <meta title=" Top Wedding Planner & Decorator In Malad | Celebrity Decorator" />
        <meta name="description" content=" India's Best Celebrity Management Agency in Mumbai SK World provides  the stars in your events with our unrivaled services across India." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/top-wedding-planner-decorator-malad" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Top Wedding Planner & Decorator In Malad | Celebrity Decorator" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content=" India's Best Celebrity Management Agency in Mumbai SK World provides  the stars in your events with our unrivaled services across India." />
        <meta property="og:image" content="https://celebritydecorator.com/static/media/Logo.32e388932c0fee99c1be.png" />
      </MetaTags>
      <Slider/>

      {/* <section className="Home-banner">
        <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div>
            <div className="slider-item-content">

<span className="medium-text" style={{color:"black"}} >Expert Strategies in Event Management</span>
<small className="small-text" style={{color:"black"}}>"Turning Visions into Unforgettable Events: Your Event Management Partner</small>
  
 
</div>
            </div>
          </div>
          <div className="col-md-6">
            <div>
            <img className="imgbannersize1" src={banner1}/>
<img className="imgbannersize2" src={banner3}/>
<img className="imgbannersize3" src={banner2}/>
            </div>
          </div>
        </div>
        </div>
      </section> */}

      {/* /////////////////////////////// Section 2 //////////////////////////////// */}
      <section id="about" className="pt-14 pb-14">
        <div className="container">
          <div
            className="row align-items-start align-items-md-stretch"
            data-cues="fadeIn"
            data-disabled="true"
          >
            <div
              className="col-12 col-lg-6 order-1 order-lg-0"
              data-cue="fadeIn"
              data-show="true"
              style={{
                animationName: "fadeIn",
                animationDuration: "1000ms",
                animationTimingFunction: "ease",
                animationDelay: "0ms",
                animationDirection: "normal",
                animationFillMode: "both",
              }}
            >
              <Slide left>
                {/* Image */}
                <div className="row g-0">
                  <div className="col-6 order-md-1 order-2">
                    <figure className="mb-0 pe-3 pe-md-4 pe-lg-5">
                      <img
                        src={img1}
                        srcSet={img1}
                        className="img-fluid w-100 rounded shadow-sm"
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="col-6 order-md-1 order-2">
                    <figure className="mb-0 pt-9">
                      <img
                        src={img2}
                        srcSet={img2}
                        className="img-fluid w-100 rounded shadow-sm "
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </Slide>
              {/* /Image */}
            </div>
            <div
              className="col-12 col-lg-6 order-0 order-lg-1 order-md-2 order-1"
              data-cue="fadeIn"
              data-show="true"
              style={{
                animationName: "fadeIn",
                animationDuration: "1000ms",
                animationTimingFunction: "ease",
                animationDelay: "0ms",
                animationDirection: "normal",
                animationFillMode: "both",
              }}
            >
              {/* Description */}
              <Slide right>
                <div className="bg-white h-100 d-flex align-items-center">
                  <div className="p-lg-5 mb-8">
                    <span className="mb-2 fw-medium text-secondary ff-sub text-uppercase ls-1 d-block">
                      Welcome to
                    </span>
                    <h2 className=" ff-heading">
                      Celebrity Decorator
                    </h2>
                    <p className="mb-6">
                   
Premier event management company specializing in celebrity decoration services, offering the best wedding planning and decorating services in Malad. Our dedicated team ensures flawless execution and attention to detail for every occasion. With a focus on perfection, we bring your dream celebration to life. As the top choice for celebrity decorators, we create unforgettable experiences that reflect sophistication and style. Trust us to turn your special day into a magical and memorable event. Choose us as your preferred wedding planner and decorator in Malad for an unparalleled blend of creativity and expertise in celebrity event decoration.
                    </p>

                    <a
                      role="button"
                      tabIndex={0}
                      href="#!"
                      className="custom-btn "
                    >
                      <Link to="/wedding-event-planner-mumbai" style={{ color: "white" }}>
                        Read More
                      </Link>
                    </a>
                  </div>
                </div>
              </Slide>
              {/* /Description */}
            </div>
          </div>
        </div>
      </section>
      <section id="rooms" className="pt-14 pb-14 bg-body" data-bs-theme="dark">
        <div className="container">
          {/* Description */}
          <div
            className="mb-10 text-center"
            data-cue="fadeIn"
            data-show="true"
            style={{
              animationName: "fadeIn",
              animationDuration: "1000ms",
              animationTimingFunction: "ease",
              animationDelay: "0ms",
              animationDirection: "normal",
              animationFillMode: "both",
            }}
          >
            <h2 className=" ff-heading mb-2 textcolor ">
              Wedding &amp; Services
            </h2>
            <h4 className="h6 fw-normal text-body-secondary ff-sub text-uppercase ">
            Elevating events with celebrity-style decor and service.
            </h4>
          </div>
          {/* /Description */}
          {/* Rooms List */}
          <div className="tns-outer" id="tns1-ow">
            <div
              className="tns-controls"
              aria-label="Carousel Navigation"
              tabIndex={0}
            ></div>

            <div id="tns1-mw" className="tns-ovh">
              <div className="tns-inner" id="tns1-iw">
                <div
                  className="room-luxury-slider  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal"
                  data-cue="fadeIn"
                  id="tns1"
                  style={{
                    animationName: "fadeIn",
                    animationDuration: "1000ms",
                    animationTimingFunction: "ease",
                    animationDelay: "0ms",
                    animationDirection: "normal",
                    animationFillMode: "both",
                  }}
                  data-show="true"
                >
                  <Service />
                </div>
              </div>
            </div>
          </div>
          {/* /Rooms List */}
        </div>
      </section>

      <section id="facilitiesAmenities" className="pt-14 pb-10">
        <div className="container">
          {/* Heading */}
          <div
            className="mb-10 text-center"
            data-cue="fadeIn"
            data-show="true"
            style={{
              animationName: "fadeIn",
              animationDuration: "1000ms",
              animationTimingFunction: "ease",
              animationDelay: "0ms",
              animationDirection: "normal",
              animationFillMode: "both",
            }}
          >
            <h2 className="ff-heading mb-2">Amenity</h2>
            <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ">
           Enhance your space with luxurious amenities
            </p>
          </div>
          {/* /Heading */}
          {/* List */}
          <div className="row" data-cues="fadeIn" data-disabled="true">
            <Flip left>
              <div
                className="col-12 col-xl-4 col-md-6"
                data-cue="fadeIn"
                data-show="true"
                style={{
                  animationName: "fadeIn",
                  animationDuration: "1000ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both",
                }}
              >
                {/* Item */}
                <div className="mb-5">
                  <div className="d-flex align-items-start">
                    <div className="fs-1 text-primary pe-5 lh-sm">
                      <img style={{ width: "100px" }} src={i1} alt="" />
                    </div>
                    <div>
                      <h3 className="h6 text-uppercase ff-sub ls-1">
                      friendly team
                      </h3>
                      <p>
                      Our friendly team is dedicated, approachable, and ready to assist with any inquiries or concerns
                      </p>
                    </div>
                  </div>
                </div>
                {/* /Item */}
              </div>
            </Flip>
            <Flip left>
              <div
                className="col-12 col-xl-4 col-md-6"
                data-cue="fadeIn"
                data-show="true"
                style={{
                  animationName: "fadeIn",
                  animationDuration: "1000ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both",
                }}
              >
                {/* Item */}
                <div className="mb-5">
                  <div className="d-flex align-items-start">
                    <div className="fs-1 text-primary pe-5 lh-sm">
                      <img style={{ width: "100px" }} src={i2} alt="" />
                    </div>
                    <div>
                      <h3 className="h6 text-uppercase ff-sub ls-1">
                      Unique Scenario
                      </h3>
                      <p>
                      Embark on a journey through a uniquely crafted scenario, where every moment unfolds uniquely
                      </p>
                    </div>
                  </div>
                </div>
                {/* /Item */}
              </div>
            </Flip>
            <Flip left>
              <div
                className="col-12 col-xl-4 col-md-6"
                data-cue="fadeIn"
                data-show="true"
                style={{
                  animationName: "fadeIn",
                  animationDuration: "1000ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both",
                }}
              >
                {/* Item */}
                <div className="mb-5">
                  <div className="d-flex align-items-start">
                    <div className="fs-1 text-primary pe-5 lh-sm">
                      <img style={{ width: "100px" }} src={i3} alt="" />
                    </div>
                    <div>
                      <h3 className="h6 text-uppercase ff-sub ls-1">
                      perfect venues
                      </h3>
                      <p>
                      Discover perfect venues for unforgettable moments, tailored to your vision and celebration needs.
                      </p>
                    </div>
                  </div>
                </div>
                {/* /Item */}
              </div>
            </Flip>
            <Flip left>
              <div
                className="col-12 col-xl-4 col-md-6"
                data-cue="fadeIn"
                data-show="true"
                style={{
                  animationName: "fadeIn",
                  animationDuration: "1000ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both",
                }}
              >
                {/* Item */}
                <div className="mb-5">
                  <div className="d-flex align-items-start">
                    <div className="fs-1 text-primary pe-5 lh-sm">
                      <img style={{ width: "100px" }} src={i4} alt="" />
                    </div>
                    <div>
                      <h3 className="h6 text-uppercase ff-sub ls-1">
                        {" "}
                        24/7 hours support
                      </h3>
                      <p>
                      Reliable assistance available around the clock for your convenience. Reach out anytime for support.
                      </p>
                    </div>
                  </div>
                </div>
                {/* /Item */}
              </div>
            </Flip>
            <Flip left>
              <div
                className="col-12 col-xl-4 col-md-6"
                data-cue="fadeIn"
                data-show="true"
                style={{
                  animationName: "fadeIn",
                  animationDuration: "1000ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both",
                }}
              >
                {/* Item */}
                <div className="mb-5">
                  <div className="d-flex align-items-start">
                    <div className="fs-1 text-primary pe-5 lh-sm">
                      <img style={{ width: "100px" }} src={i5} alt="" />
                    </div>
                    <div>
                      <h3 className="h6 text-uppercase ff-sub ls-1">
                      Unforgetable Times
                      </h3>
                      <p>
                      Create memories that last a lifetime with unforgettable moments and cherished experiences together
                      </p>
                    </div>
                  </div>
                </div>
                {/* /Item */}
              </div>
            </Flip>
            <Flip left>
              <div
                className="col-12 col-xl-4 col-md-6"
                data-cue="fadeIn"
                data-show="true"
                style={{
                  animationName: "fadeIn",
                  animationDuration: "1000ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both",
                }}
              >
                {/* Item */}
                <div className="mb-5">
                  <div className="d-flex align-items-start">
                    <div className="fs-1 text-primary pe-5 lh-sm">
                      <img style={{ width: "100px" }} src={i7} alt="" />
                    </div>
                    <div>
                      <h3 className="h6 text-uppercase ff-sub ls-1">
                      Briliant Idea
                      </h3>
                      <p>
                      Spark a brilliant idea that illuminates possibilities and propels you toward success and fulfillment
                      </p>
                    </div>
                  </div>
                </div>
                {/* /Item */}
              </div>
            </Flip>
          </div>
          {/* /List */}
        </div>
      </section>
      <section className="Home-Section-5">
        <div className="Form-Section">
          <div className="Form-Image-con">
            <Fade left>
              <div className="Form-Image">
                <img
                  src={Enquery}
                  alt=""
                  className="react-reveal"
                  style={{
                    animationFillMode: "both",
                    animationDuration: "1000ms",
                    animationDelay: "0ms",
                    animationIterationCount: 1,
                    opacity: 1,
                    animationName: "react-reveal-264739228441732-4",
                  }}
                />
              </div>
            </Fade>
          </div>
          <Fade right>
            <div
              className="react-reveal Form-Data-con"
              style={{
                animationFillMode: "both",
                animationDuration: "1000ms",
                animationDelay: "0ms",
                animationIterationCount: 1,
                opacity: 1,
                animationName: "react-reveal-264739228441732-5",
              }}
            >
              <div className="Form-Data">
                <div className="Form-Info">
                 
                
                  <h2 className="ff-heading mb-2" style={{marginBottom:"0px"}}>Enquiry Now</h2>
                  <h6 className="mb-2"  style={{fontSize:" 1.06875rem"}}>SIGN UP</h6>
                  <p>
                    For the latest inspiration and insider tips straight to your
                    inbox.
                  </p>
                </div>
                <div className="formitem ">

              
                <div className="Form-Inputs">
                  <form className="HomeForm" ref={formRef}  {...props}>
                    <label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Full Name"
                        onChange={(e) => setCustName(e.target.value)}
                      />
                    </label>
                    <label>
                      <input
                        type="text"
                        name="email"
                        placeholder="Your email address"
                        onChange={(e) => setCustEmail(e.target.value)}
                      />
                    </label>
                    <label>
                      <input
                        type="number"
                        name="name"
                        placeholder="Your Contact Number"
                        onChange={(e) => setCustContact(e.target.value)} 
                      />
                    </label>
                    <button className="form-submit-button" type="submit"  onClick={Appoinment} >Submit</button>
                  </form>
                </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </section>
      <section id="facilitiesAmenities" className="pt-14 pb-10">
        <div className="container">
          {/* Heading */}
          <div
            className="mb-10 text-center"
            data-cue="fadeIn"
            data-show="true"
            style={{
              animationName: "fadeIn",
              animationDuration: "1000ms",
              animationTimingFunction: "ease",
              animationDelay: "0ms",
              animationDirection: "normal",
              animationFillMode: "both",
            }}
          >
            <h2 className=" ff-heading mb-2">Gallery</h2>
            <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ">
              An quis eligendi atomorum latine
            </p>
          </div>
          {/* /Heading */}
          {/* List */}
          <div id="image-gallery" className="row">
      <a
        className="col-md-4 "
        style={{paddingBottom:"20px"}}
        href={Enquery}
        data-lg-size="1600-2400"
        data-lg-srcset={Enquery}
      >
        <img src={Enquery} alt="Enquery" />
      </a>
      <a
        className="col-md-4"
        style={{paddingBottom:"20px"}}
        href={banner1}
        data-lg-size="1600-2400"
        data-lg-srcset={banner1}
      >
        <img src={banner1} alt="Banner 1" />
      </a>
      <a
        className="col-md-4"
        style={{paddingBottom:"20px"}}
        href={banner6}
        data-lg-size="1600-2400"
        data-lg-srcset={banner6}
      >
        <img src={banner6} alt="Banner 6" />
      </a>
      <a
        className="col-md-4"
        style={{paddingBottom:"20px"}}
        href={banner7}
        data-lg-size="1600-2400"
        data-lg-srcset={banner7}
      >
        <img src={banner7} alt="Banner 7" />
      </a>
      <a
        className="col-md-4"
        style={{paddingBottom:"20px"}}
        href={banner4}
        data-lg-size="1600-2400"
        data-lg-srcset={banner4}
      >
        <img src={banner4} alt="Banner 4" />
      </a>
      <a
        className="col-md-4"
        style={{paddingBottom:"20px"}}
        href={banner5}
        data-lg-size="1600-2400"
        data-lg-srcset={banner5}
      >
        <img src={banner5} alt="Banner 5" />
      </a>
    </div>
<div style={{display:"flex",justifyContent:"center",textAlign:"center"}}>
<a
                      role="button"
                      tabIndex={0}
                      href="#!"
                      className="custom-btn "
                    >
                      <Link to="/Galleryer" style={{ color: "white" }}>
                        Read More
                      </Link>
                    </a>
</div>
          {/* /List */}
        </div>
      </section>
      <section id="facilitiesAmenities" className="pt-14 pb-10">
        <div className="container">
          {/* Heading */}
          <div
            className="mb-10 text-center"
            data-cue="fadeIn"
            data-show="true"
            style={{
              animationName: "fadeIn",
              animationDuration: "1000ms",
              animationTimingFunction: "ease",
              animationDelay: "0ms",
              animationDirection: "normal",
              animationFillMode: "both",
            }}
          >
            <h2 className=" ff-heading mb-2">Testimonial</h2>
            <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ">
            Dream events, flawlessly executed.
            </p>
          </div>
          {/* /Heading */}
          {/* List */}
          <Testimonial2 />
          {/* /List */}
        </div>
      </section>
      {/* <section
        id="facilitiesAmenities"
        className="pt-14 pb-10"
        style={{ backgroundColor: "whitesmoke" }}
      >
        <div className="container"> */}
          {/* Heading */}
          {/* <div
            className="mb-10 text-center"
            data-cue="fadeIn"
            data-show="true"
            style={{
              animationName: "fadeIn",
              animationDuration: "1000ms",
              animationTimingFunction: "ease",
              animationDelay: "0ms",
              animationDirection: "normal",
              animationFillMode: "both",
            }}
          >
            <h2 className="ff-heading mb-2">Awards</h2>
            <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ">
            Award-worthy events, perfected with flair
            </p>
          </div> */}
          {/* /Heading */}
          {/* List */}
          {/* <Awords /> */}
          {/* /List */}
        {/* </div>
      </section> */}
<p style={{display:"none"}}>
If you're in search of the top wedding planner and decorator in Malad, look no further! Our team is dedicated to turning your dream wedding into a reality. As the premier wedding planner and decorator in Malad, we pride ourselves on creating unforgettable moments that will be cherished for a lifetime.

Our experienced team of professionals understands the significance of your special day and strives to make it truly magical. With our expertise in wedding planning and decoration, we ensure that every detail is meticulously taken care of, leaving you stress-free to enjoy the celebration.

As the go-to wedding planner and decorator in Malad, we offer a wide range of services tailored to your specific needs. From conceptualizing and planning the entire event to executing the finest details of decoration, we are committed to making your wedding day absolutely perfect.

Imagine a wedding where every element reflects your unique style and personality. That's precisely what our top-notch team aims to achieve. We are not just wedding planners and decorators; we are storytellers who craft narratives through impeccable designs and seamless execution.

Choosing the right wedding planner and decorator in Malad is crucial to ensuring a smooth and delightful journey from engagement to the grand finale. With our dedicated team by your side, you can relax and savor every moment, knowing that we are here to make your dreams come true.

So, whether you envision a classic, elegant affair or a modern, vibrant celebration, our top wedding planner and decorator in Malad are ready to bring your vision to life. Contact us today to start planning the wedding of your dreams with the most trusted professionals in the industry. Your perfect day is just a step away with the top wedding planner and decorator in Malad.</p>
      <Footer></Footer>
    </div>
  );
};

export default Home;
