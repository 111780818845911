import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import logo from "../Asset/Logo.png"
// Import Swiper styles
import 'swiper/css';


// import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
const Team = () => {
  return (
    <>
       <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
       
      
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div style={{padding:"0px 42px 0px 20px"}}>
          <div className="row">
            <div className="col-md-4">
              <img
                src={logo}
                alt=""
                style={{ width: "80%"}}
              />
            </div>
            <div className="col-md-7">
              <div>
                <div>
                  <h3 className="h6 text-uppercase ff-sub ls-1">Founder</h3>
                  <p style={{textAlign:"justify",color:"black"}}>   
                    Mr. Ashok Chawan has an experience of 15 years in the
                    wedding industry. Initially, he used to execute outsourced
                    weddings from other wedding decorators. After giving
                    excellent service to other decorators he founded Celebrity
                    decorator. With years of knowledge and experience, he has
                    been able to provide a fantastic wedding experience to over
                    2000 clients.
                  </p>
                </div>
              </div>
            </div>
          </div> 
          </div>
        
        </SwiperSlide>
    </Swiper>
    </>
  );
};

export default Team;
