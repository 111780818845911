import React, { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../css/Style.css";
import Flip from "react-reveal/Flip";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import banner1 from "../Asset/Banner/banner1.jpg";
import banner2 from "../Asset/Banner/banner2.jpg";
import banner3 from "../Asset/Banner/banner3.jpg";
import banner4 from "../Asset/Banner/banner4.jpg";
import banner5 from "../Asset/Banner/banner5.jpg";
import BAN1 from "../Asset/BAN1.jpg";
import BAN2 from "../Asset/BAN2.jpg";
import BAN3 from "../Asset/BAN3.jpg";
import BAN4 from "../Asset/BAN4.jpg";
import BAN5 from "../Asset/BAN5.jpg";
import BAN6 from "../Asset/BAN6.jpg";
import BAN7 from "../Asset/BAN7.jpg";
import bannedemo1 from "../Asset/Banner/bannerdemo1.jpeg"
import bannedemo2 from "../Asset/Banner/bannerdemo2.jpg"
import { Pagination, Navigation } from "swiper/modules";
const Slider = () => {
  return (
    <div>
      <div className="homebanner">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        // autoplay={{
        //   delay: 3500,
        //   disableOnInteraction: false,
        // }}
        keyboard={{
          enabled: true,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="owl-item margintop" style={{ width: "100%" }}>
            <div
              className="item bannerIMAGES1 image-banner"
              style={{ backgroundImage: `url(${bannedemo2})` }}
            >
              <div className="overlay-black">
                <div className="container sliderouter">
                  <div className="bannerhead">
                    <div className="slider-item-content">
                      <h1 className="textcenter colorWhi">
                        Exceeding Expectations, Every Event
                      </h1>
                      <p className="textcenter colorWhi">
                        Event management Crafting seamless, unforgettable
                        experiences for you
                      </p>
                      <div className="textcenter">
                        <button
                          role="button"
                          tabIndex={0}
                          href="#!"
                          className="custom-btn "
                          // onClick={() => setModalShow(true)}
                        >
                          <Link to="/wedding-event-planner-mumbai" style={{color:"white"}}>
                          Read More
                          </Link>
                          
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="owl-item margintop" style={{ width: "100%" }}>
            <div
              className="item bannerIMAGES1 image-banner"
              style={{ backgroundImage: `url(${bannedemo1})` }}
            >
              <div className="overlay-black">
                <div className="container sliderouter">
                  <div className="bannerhead">
                    <div className="slider-item-content">
                      <h1 className="textcenter colorWhi">
                        Where Dreams Become Events
                      </h1>
                      <p className="textcenter colorWhi">
                        Event management Crafting seamless, unforgettable
                        experiences for you
                      </p>
                      <div className="textcenter">
                        <button
                          role="button"
                          tabIndex={0}
                          href="#!"
                          className="custom-btn "
                          // onClick={() => setModalShow(true)}
                        >
                          <Link to="/wedding-event-planner-mumbai" style={{color:"white"}}>
                          Read More
                          </Link>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="owl-item margintop" style={{ width: "100%" }}>
            <div
              className="item bannerIMAGES1 image-banner"
              style={{ backgroundImage: `url(${BAN7})` }}
            >
              <div className="overlay-black">
                <div className="container sliderouter">
                  <div className="bannerhead">
                    <div className="slider-item-content">
                      <h1 className="textcenter colorWhi">
                        Seamless Events, Endless Memories
                      </h1>
                      <p className="textcenter colorWhi">
                        Event management Crafting seamless, unforgettable
                        experiences for you
                      </p>
                      <div className="textcenter">
                        <button
                          role="button"
                          tabIndex={0}
                          href="#!"
                          className="custom-btn "
                          // onClick={() => setModalShow(true)}
                        >
                          <Link to="/wedding-event-planner-mumbai" style={{color:"white"}}>
                          Read More
                          </Link>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      </div>
    </div>
  );
};

export default Slider;
