import React from 'react'
import banner1 from "../Asset/Banner/banner1.jpg"
import Footer from '../Components/Footer'
import { FaLocationDot } from 'react-icons/fa6';
import Enquiryform from '../Components/Enquiryform';
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import { MetaTags } from 'react-meta-tags';
const Comedians = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
       <MetaTags>
        <title> Comedians for weddings In Mumbai | Celebrity Decorator </title>
        <meta title="  Comedians for weddings In Mumbai | Celebrity Decorator " />
        <meta name="description" content="Turn your wedding into a laughter-filled extravaganza! Book talented Comedians for Weddings in Mumbai, adding humor and joy to your special day." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/comedians-weddings-mumbai" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Comedians for weddings In Mumbai | Celebrity Decorator " />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content="Turn your wedding into a laughter-filled extravaganza! Book talented Comedians for Weddings in Mumbai, adding humor and joy to your special day." />
        <meta property="og:image" content="https://celebritydecorator.com/static/media/Logo.32e388932c0fee99c1be.png" />
      </MetaTags>
    <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
<div
className="jarallax"
style={{ backgroundImage: "none", backgroundSize:"cover", zIndex: 0 , backgroundImage: `url(${comann})` , }}

>
<div className="overlay-black">
<div className="container">
  <div className="row justify-content-center">
    <div className="col-lg-6 col-md-12 col-sm-12">
      {/* breadcrumb-title - start */}
      <div className="breadcrumb-title text-center mb-50">
        <span className="sub-title">Celebrity Decorator</span>
        <h2 className="big-title">
          <strong>Comedians</strong> 
        </h2>
      </div>
      {/* breadcrumb-title - end */}
      {/* breadcrumb-list - start */}
      <div className="breadcrumb-list">
        <ul>
          <li className="breadcrumb-item">
            <a href="index-1.html" className="breadcrumb-link">
              Home
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            about us
          </li>
        </ul>
      </div>
      {/* breadcrumb-list - end */}
    </div>
  </div>
</div>
</div>
<div
id="jarallax-container-0"
style={{
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  overflow: "hidden",
  pointerEvents: "none",
  zIndex: -100
}}
>
<div
  style={{
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${banner1})` ,
    position: "fixed",
    top: 0,
    left: 0,

    height: "553.66px",
    overflow: "hidden",
    pointerEvents: "none",
    marginTop: "49.17px",
   
  }}
/>
</div>
</div>
</section>
<div >

<div className="grid container">
  <div className="row">
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/artistThumbnailImage/5bdae058d6524__Sunil-Pal-Comedian-India.jpg" alt="Snowy Mountains" />
            <div className="card__content">

              <h3 className="card__header" style={{margin:"0px"}}>Sunil Pal</h3>
              <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/artistThumbnailImage/5bdae03f01a89__Sunil-Grover-Comedian.jpg" alt="Snowy Mountains" />
            <div className="card__content">
              <h3 className="card__header" style={{margin:"0px"}}>Sunil Grover</h3>
              <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    
  
  </div>
  
        
      </div>
<Enquiryform
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
</div>
<p style={{display:"none"}}>
  
When it comes to adding a touch of humor and laughter to weddings in Mumbai, hiring a talented comedian can be the perfect choice. Mumbai, known for its vibrant culture and lively celebrations, offers a plethora of comedic talent to make your special day even more memorable.

Imagine the joyous atmosphere as a seasoned comedian takes the stage, weaving humor into the fabric of your wedding festivities. These comedians specialize in creating light-hearted moments that bring smiles to the faces of your guests, turning your wedding into an event that will be talked about for years to come.

Mumbai's comedy scene boasts a diverse array of performers, each with their own unique style and comedic flair. From observational humor to witty anecdotes, these comedians know how to tailor their acts to suit the occasion. Whether you're looking for stand-up comedy, improv, or a combination of both, there's a comedian in Mumbai who can cater to your specific preferences.

Incorporating a comedian into your wedding entertainment lineup not only adds laughter but also helps break the ice among guests, creating a warm and enjoyable atmosphere. As the laughter echoes through the venue, the comedian becomes a catalyst for shared joy, making the wedding experience even more delightful.

Mumbai's wedding comedy scene is teeming with professionals who understand the nuances of crafting humor that resonates with diverse audiences. So, if you're seeking a memorable and amusing addition to your wedding festivities in Mumbai, consider enlisting the services of a local comedian. Their ability to infuse joy and laughter into your special day ensures a celebration that will be cherished by everyone in attendance.
</p>
<Footer></Footer>
</div>
  )
}

export default Comedians
