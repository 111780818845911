import React from 'react'
import banner1 from "../Asset/Banner/banner1.jpg"
import Footer from '../Components/Footer'
import { FaLocationDot } from 'react-icons/fa6';
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import Enquiryform from '../Components/Enquiryform';
import { MetaTags } from 'react-meta-tags';
const Singers = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
       <MetaTags>
        <title>Wedding Singers In Mumbai | Celebrity Decorator </title>
        <meta title=" Wedding Singers In Mumbai | Celebrity Decorator " />
        <meta name="description" content=" Discover the enchanting voices of our skilled Wedding Singers in Mumbai. From timeless classics to contemporary hits." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/wedding-singers-mumbai" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Wedding Singers In Mumbai | Celebrity Decorator " />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content=" Discover the enchanting voices of our skilled Wedding Singers in Mumbai. From timeless classics to contemporary hits." />
        <meta property="og:image" content="https://celebritydecorator.com/static/media/Logo.32e388932c0fee99c1be.png" />
      </MetaTags>
    <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
<div
className="jarallax"
style={{ backgroundImage: "none", backgroundSize:"cover", zIndex: 0 , backgroundImage: `url(${comann})` , }}

>
<div className="overlay-black">
<div className="container">
  <div className="row justify-content-center">
    <div className="col-lg-6 col-md-12 col-sm-12">
      {/* breadcrumb-title - start */}
      <div className="breadcrumb-title text-center mb-50">
        <span className="sub-title">Celebrity Decorator</span>
        <h2 className="big-title">
          <strong>Singers</strong> 
        </h2>
      </div>
      {/* breadcrumb-title - end */}
      {/* breadcrumb-list - start */}
      <div className="breadcrumb-list">
        <ul>
          <li className="breadcrumb-item">
            <a href="index-1.html" className="breadcrumb-link">
              Home
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            about us
          </li>
        </ul>
      </div>
      {/* breadcrumb-list - end */}
    </div>
  </div>
</div>
</div>
<div
id="jarallax-container-0"
style={{
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  overflow: "hidden",
  pointerEvents: "none",
  zIndex: -100
}}
>
<div
  style={{
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${banner1})` ,
    position: "fixed",
    top: 0,
    left: 0,

    height: "553.66px",
    overflow: "hidden",
    pointerEvents: "none",
    marginTop: "49.17px",
   
  }}
/>
</div>
</div>
</section>
<div >

<div className="grid container">
  <div className="row">
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/artistThumbnailImage/5bdae340cd4ba__Sukhwinder-Singh.jpg" alt="Snowy Mountains" />
            <div className="card__content">

              <h3 className="card__header" style={{margin:"0px"}}>Sukhwinder Singh</h3>
             <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://dreamzkraft.com/admin/artistThumbnailImage/5bdae32a662bf__Rimi-Nique.jpg" alt="Snowy Mountains" />
            <div className="card__content">
              <h3 className="card__header" style={{margin:"0px"}}>Rimi Nique</h3>
             <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    
  
  </div>
  
        
      </div>
<Enquiryform
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
</div>
<p style={{display:"none"}}>
  
Planning a wedding in Mumbai and looking for the perfect musical touch to make your special day truly unforgettable? Look no further than the vibrant and diverse pool of wedding singers in Mumbai. These talented professionals add a melodic charm to your celebrations, creating an atmosphere filled with love and joy.

Mumbai, known for its rich cultural tapestry, offers a plethora of wedding singers who can cater to a variety of musical preferences. Whether you envision a soulful rendition of classic love songs, a lively performance of Bollywood hits, or a fusion of different genres, you can find the perfect match for your taste.

One of the key advantages of hiring a wedding singer in Mumbai is the ability to personalize your musical experience. These artists are often versatile and can adapt their repertoire to suit the mood and theme of your wedding. Imagine walking down the aisle to the sweet serenade of a live vocalist, or having a dynamic performance to kick off your reception – these are the moments that turn ordinary weddings into extraordinary ones.

In a city known for its bustling energy, wedding singers in Mumbai bring a unique blend of passion and professionalism to your event. Their experience in the industry allows them to navigate the diverse musical landscape, ensuring that your guests are entertained throughout the festivities.

From the pre-wedding ceremonies to the grand reception, these singers can seamlessly integrate with the flow of your event. Their ability to engage the audience and create a magical ambiance makes them an essential part of any wedding celebration in Mumbai.

So, if you're on the lookout for talented wedding singers in Mumbai to elevate your nuptial experience, explore the local talent pool and discover the perfect voice to soundtrack your love story. Let the music speak volumes on your special day, adding a harmonious touch that will be cherished for a lifetime.
</p>
<Footer></Footer>
</div>
  )
}

export default Singers
