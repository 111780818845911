import React from 'react'

import banner1 from "../Asset/Banner/banner1.jpg"
import Footer from '../Components/Footer'
import { FaLocationDot } from 'react-icons/fa6';
import Enquiryform from '../Components/Enquiryform';
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import { MetaTags } from 'react-meta-tags';
const Invitattionscard = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
       <MetaTags>
        <title>Weddings Invitation Cards in Mumbai | Celebrity Decorator </title>
        <meta title=" Weddings Invitation Cards in Mumbai | Celebrity Decorator " />
        <meta name="description" content=" Discover exquisite Wedding Invitation Cards in Mumbai that add a touch of elegance to your celebration. Choose from our stunning collection." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/wedding-invitation-cards-mumbai" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Weddings Invitation Cards in Mumbai | Celebrity Decorator " />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content=" Discover exquisite Wedding Invitation Cards in Mumbai that add a touch of elegance to your celebration. Choose from our stunning collection." />
        <meta property="og:image" content="https://celebritydecorator.com/static/media/Logo.32e388932c0fee99c1be.png" />
      </MetaTags>
    <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
<div
className="jarallax"
style={{ backgroundImage: "none", backgroundSize:"cover", zIndex: 0 , backgroundImage: `url(${comann})` , }}

>
<div className="overlay-black">
<div className="container">
 <div className="row justify-content-center">
   <div className="col-lg-6 col-md-12 col-sm-12">
     {/* breadcrumb-title - start */}
     <div className="breadcrumb-title text-center mb-50">
       <span className="sub-title">Celebrity Decorator</span>
       <h2 className="big-title">
         <strong>Invitattions cards & Wedding Stationery </strong> 
       </h2>
     </div>
     {/* breadcrumb-title - end */}
     {/* breadcrumb-list - start */}
     <div className="breadcrumb-list">
       <ul>
         <li className="breadcrumb-item">
           <a href="index-1.html" className="breadcrumb-link">
             Home
           </a>
         </li>
         <li className="breadcrumb-item active" aria-current="page">
           about us
         </li>
       </ul>
     </div>
     {/* breadcrumb-list - end */}
   </div>
 </div>
</div>
</div>
<div
id="jarallax-container-0"
style={{
 position: "absolute",
 top: 0,
 left: 0,
 width: "100%",
 height: "100%",
 overflow: "hidden",
 pointerEvents: "none",
 zIndex: -100
}}
>
<div
 style={{
   backgroundPosition: "50% 50%",
   backgroundSize: "cover",
   backgroundRepeat: "no-repeat",
   backgroundImage: `url(${banner1})` ,
   position: "fixed",
   top: 0,
   left: 0,

   height: "553.66px",
   overflow: "hidden",
   pointerEvents: "none",
   marginTop: "49.17px",
  
 }}
/>
</div>
</div>
</section>


<div className="grid container">
  <div className="row">
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/vendorThumbnailImage/5bc20fadaef43__Itchha%20Talreja%20Designs.jpg" alt="Snowy Mountains" />
            <div className="card__content">

              <h3 className="card__header" style={{margin:"0px"}}>Itchha Talreja Designs</h3>
            <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/vendorThumbnailImage/5bc2117208f8c__artsy-invitation-cards.jpg" alt="Snowy Mountains" />
            <div className="card__content">
              <h3 className="card__header" style={{margin:"0px"}}>Artsy Design</h3>
            <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    
  
  </div>
  
        
      </div>

<Footer/>
<Enquiryform
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
</div>
  )
}

export default Invitattionscard
