import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../Components/images"
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import banner1 from "../Asset/Banner/banner1.jpg"
import Footer from "../Components/Footer";
const Galleryer = () => {
    const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
        <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
<div
className="jarallax"
style={{ backgroundImage: "none", backgroundSize:"cover", zIndex: 0 , backgroundImage: `url(${comann})` , }}

>
<div className="overlay-black">
<div className="container">
 <div className="row justify-content-center">
   <div className="col-lg-6 col-md-12 col-sm-12">
     {/* breadcrumb-title - start */}
     <div className="breadcrumb-title text-center mb-50">
       <span className="sub-title">Celebrity Decorator</span>
       <h2 className="big-title">
         <strong>Gallery </strong> 
       </h2>
     </div>
     {/* breadcrumb-title - end */}
     {/* breadcrumb-list - start */}
     <div className="breadcrumb-list">
       <ul>
         <li className="breadcrumb-item">
           <a href="index-1.html" className="breadcrumb-link">
             Home
           </a>
         </li>
         <li className="breadcrumb-item active" aria-current="page">
           about us
         </li>
       </ul>
     </div>
     {/* breadcrumb-list - end */}
   </div>
 </div>
</div>
</div>
<div
id="jarallax-container-0"
style={{
 position: "absolute",
 top: 0,
 left: 0,
 width: "100%",
 height: "100%",
 overflow: "hidden",
 pointerEvents: "none",
 zIndex: -100
}}
>
<div
 style={{
   backgroundPosition: "50% 50%",
   backgroundSize: "cover",
   backgroundRepeat: "no-repeat",
   backgroundImage: `url(${banner1})` ,
   position: "fixed",
   top: 0,
   left: 0,

   height: "553.66px",
   overflow: "hidden",
   pointerEvents: "none",
   marginTop: "49.17px",
  
 }}
/>
</div>
</div>
</section>
<div style={{padding:"10px"}}>
    <Gallery photos={photos} onClick={openLightbox} />
    <ModalGateway>
      {viewerIsOpen ? (
        <Modal onClose={closeLightbox}>
          <Carousel
            currentIndex={currentImage}
            views={photos.map(x => ({
              ...x,
              srcset: x.srcSet,
              caption: x.title
            }))}
          />
        </Modal>
      ) : null}
    </ModalGateway>
    </div>
    <Footer></Footer>
  </div>
  )
}

export default Galleryer
